import React from 'react'

import Layout from '../components/layout'
import ComplainsOne from '../components/strategy/ComplainsOne'


const Work = props => (
  <Layout lang="en" title="Complaints Management Policy" meta={{description: ''}} rootProps={{ className: 'page strategy' }}>
    <ComplainsOne lang="en" />
  </Layout>
)

export default Work
